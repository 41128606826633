import { mapKeys } from 'lodash-es'

export const IN_PROGRESS = 'IN_PROGRESS'
export const COMPLETED = 'COMPLETED'
export const ABORTED = 'ABORTED'
export const FAILED = 'FAILED'

export const MENU_PROBING_JOB_STATUSES = mapKeys(
  [
    { name: 'In progress', value: IN_PROGRESS },
    { name: 'Completed', value: COMPLETED },
    { name: 'Aborted', value: ABORTED },
    { name: 'Failed', value: FAILED },
  ],
  'value',
)
