import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  Col,
  Form,
  Modal,
  Row,
  Select as AntSelect,
  Table,
  Typography,
} from 'antd'
import { find, forEach, includes, map, values } from 'lodash-es'
import PropTypes from 'prop-types'

import { PopoverReactJson } from '../../../../../../core/components'
import { Button, Panel } from '../../../../../../core/components/styled'
import { getQueryParams } from '../../../../../../core/utils/services/queryParams'

import {
  LondonDateTime,
  MenuIdInput,
  MenuNotScrapeableReasonsFilter,
  MenuScrapingClusterRolesFilter,
  MenuShowSimilarFilter,
  MenuShowStandaloneFilter,
  MenuStatusFilter,
  ScrapingStatusFilter,
} from '../../../../../../common/components'
import {
  ADMIN,
  DEFAULT_PAGE,
  SCRAPING_STATUSES,
} from '../../../../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../../../common/helpers'
import {
  useIsLoggedInUser,
  useUserHasRoles,
} from '../../../../../../common/hooks'
import { getTableSortingOrder } from '../../../../../../common/utils'

import {
  MenuFormatFilter,
  MenuNotesForm,
  MenuUrlForm,
} from '../../../../../../menus/components/pages/sections'
import {
  HTML,
  HTML_NOT_SCRAPEABLE,
  MENU_FORMATS,
  MENU_NOT_SCRAPEABLE_REASONS,
  MENU_SCRAPING_CLUSTER_ROLES,
  MENU_STATUSES,
  PDF,
} from '../../../../../../menus/constants'
import {
  COMPLEX,
  CORE,
  LEAD,
} from '../../../../../../menus/constants/menu-scraping-cluster-roles'
import { UPDATE_CLUSTER_MENU } from '../../../../../../menus/graphql/mutations'
import {
  MARK_MENU_AS_SCRAPING_CUSTER_CORE,
  REGENERATE_COMPLEX_MENUS_FOR_SCRAPING_CLUSTER,
  REMOVE_MENU_FROM_MENU_SCRAPING_CLUSTER,
  UNMARK_MENU_AS_SCRAPING_CUSTER_CORE,
} from '../../../../../graphql/mutations'
import { MENU_SCRAPING_CLUSTER_MENUS } from '../../../../../graphql/queries'
import UpdateMenuScrapingClusterForm from '../../../../forms/update-menu-scraping-cluster-form'

const MENUS_SORTING_CRITERIA = {
  SCRAPED_DATE: 'SCRAPED_DATE',
  ATTACHED_DATE: 'ATTACHED_DATE',
}

const getSortingCriterion = (field, order) => {
  if (!order) return undefined

  switch (field) {
    case 'scrapedDate':
      return MENUS_SORTING_CRITERIA.SCRAPED_DATE
    case 'attachedToScrapingClusterDate':
      return MENUS_SORTING_CRITERIA.ATTACHED_DATE
    default:
      return undefined
  }
}

const MenuScrapingClusterMenusTable = ({
  menuScrapingCluster,
  onRemoveMenuFromMenuScrapingCluster,
  isUpdateMenuScrapingClusterHidden,
  isUpdateRoleButtonHidden,
  isRemoveButtonHidden,
  isMenuUpdateDisabled,
  isMenuNotesDisabled,
}) => {
  const [menusPage, setMenusPage] = useState(DEFAULT_PAGE)
  const [menusSortingOrder, setMenusSortingOrder] = useState()
  const [menusSortingCriterion, setMenusSortingCriterion] = useState()
  const [currentMenuId, setCurrentMenuId] = useState(null)
  const [currentMenuTempFormat, setCurrentMenuTempFormat] = useState(null)
  const [reasonNotSelectedError, setReasonNotSelectedError] = useState(false)
  const [menusTempData, setMenusTempData] = useState({})

  const { search } = useLocation()

  const isLoggedInUser = useIsLoggedInUser(menuScrapingCluster.assignedTo)
  const isAdmin = useUserHasRoles(ADMIN)

  const {
    menuId,
    menuFormats,
    menuStatuses,
    scrapingStatuses,
    scrapingClusterRoles,
    menuNotScrapeableReasons,
    showSimilarMenus,
    showStandaloneMenus,
  } = useMemo(() => getQueryParams(search), [search])

  useEffect(() => {
    setMenusPage(DEFAULT_PAGE)
  }, [
    menuStatuses,
    scrapingStatuses,
    scrapingClusterRoles,
    menuNotScrapeableReasons,
    showSimilarMenus,
    showStandaloneMenus,
    menuId,
  ])

  const menuFormatOptions = useMemo(
    () =>
      map(values(MENU_FORMATS), ({ name, value }) => (
        <AntSelect.Option key={value} value={value}>
          {name}
        </AntSelect.Option>
      )),
    [],
  )

  const notScrapableReasonOptions = useMemo(
    () =>
      map(values(MENU_NOT_SCRAPEABLE_REASONS), ({ name, value }) => (
        <AntSelect.Option key={value} value={value}>
          {name}
        </AntSelect.Option>
      )),
    [],
  )

  const leadMenuIds = useMemo(() => {
    if (!menuScrapingCluster) return []

    return map(
      menuScrapingCluster.menuScrapingClusterFingerprints || [],
      fingerprints => Number(fingerprints.menuId),
    )
  }, [menuScrapingCluster])

  const {
    data: { menuScrapingClusterMenus: { menus = [], total = 0 } = {} } = {},
    loading: isLoadingMenus,
    refetch: refetchMenus,
  } = useQuery(MENU_SCRAPING_CLUSTER_MENUS, {
    variables: {
      id: menuScrapingCluster.id,
      page: menusPage,
      filters: {
        menuId,
        menuFormats,
        menuStatuses,
        scrapingStatuses,
        scrapingClusterRoles,
        menuNotScrapeableReasons,
        showSimilarMenus,
        showStandaloneMenus,
      },
      sortingOrder: menusSortingOrder,
      sortingCriterion: menusSortingCriterion,
    },
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setCurrentMenuId(null)
      setCurrentMenuTempFormat(null)
      setReasonNotSelectedError(false)
      setMenusTempData({})
    },
  })

  const [regenerateComplexMenusForScrapingCluster] = useMutation(
    REGENERATE_COMPLEX_MENUS_FOR_SCRAPING_CLUSTER,
    {
      onCompleted() {
        refetchMenus({
          id: menuScrapingCluster.id,
          page: menusPage,
          filters: {
            menuId,
            menuFormats,
            menuStatuses,
            scrapingStatuses,
            scrapingClusterRoles,
            menuNotScrapeableReasons,
            showSimilarMenus,
            showStandaloneMenus,
          },
          sortingOrder: menusSortingOrder,
          sortingCriterion: menusSortingCriterion,
        })
        showSuccessNotification({
          message: 'Complex menus regenerated.',
          description:
            'Complex menus for the cluster have been successfully regenerated.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Complex menus regeneration failed.',
          description: message,
        })
      },
    },
  )

  const [removeMenuFromMenuScrapingCluster] = useMutation(
    REMOVE_MENU_FROM_MENU_SCRAPING_CLUSTER,
    {
      onCompleted() {
        onRemoveMenuFromMenuScrapingCluster()
        refetchMenus({
          id: menuScrapingCluster.id,
          page: menusPage,
          filters: {
            menuId,
            menuFormats,
            menuStatuses,
            scrapingStatuses,
            scrapingClusterRoles,
            menuNotScrapeableReasons,
            showSimilarMenus,
            showStandaloneMenus,
          },
          sortingOrder: menusSortingOrder,
          sortingCriterion: menusSortingCriterion,
        })
        showSuccessNotification({
          message: 'Menu removed from menu scraping cluster.',
          description:
            'Menu has been successfully removed from menu scraping cluster.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Menu removal from menu scraping cluster failed.',
          description: message,
        })
      },
    },
  )

  const [markMenuAsScrapingClusterCore] = useMutation(
    MARK_MENU_AS_SCRAPING_CUSTER_CORE,
    {
      onCompleted() {
        refetchMenus({
          id: menuScrapingCluster.id,
          page: menusPage,
          filters: {
            menuId,
            menuFormats,
            menuStatuses,
            scrapingStatuses,
            scrapingClusterRoles,
            menuNotScrapeableReasons,
            showSimilarMenus,
            showStandaloneMenus,
          },
          sortingOrder: menusSortingOrder,
          sortingCriterion: menusSortingCriterion,
        })
        showSuccessNotification({
          message: 'Marking menu as core for scraping cluster succeeded.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Marking menu as core for scraping cluster failed.',
          description: message,
        })
      },
    },
  )

  const [unmarkMenuAsScrapingClusterCore] = useMutation(
    UNMARK_MENU_AS_SCRAPING_CUSTER_CORE,
    {
      onCompleted() {
        refetchMenus({
          id: menuScrapingCluster.id,
          page: menusPage,
          filters: {
            menuId,
            menuFormats,
            menuStatuses,
            scrapingStatuses,
            scrapingClusterRoles,
            menuNotScrapeableReasons,
            showSimilarMenus,
            showStandaloneMenus,
          },
          sortingOrder: menusSortingOrder,
          sortingCriterion: menusSortingCriterion,
        })
        showSuccessNotification({
          message: 'Unmarking menu as core for scraping cluster succeeded.',
        })
      },

      onError({ message }) {
        showErrorNotification({
          message: 'Unmarking menu as core for scraping cluster failed.',
          description: message,
        })
      },
    },
  )

  useEffect(() => {
    refetchMenus({
      id: menuScrapingCluster.id,
      page: menusPage,
      filters: {
        menuId,
        menuFormats,
        menuStatuses,
        scrapingStatuses,
        scrapingClusterRoles,
        menuNotScrapeableReasons,
        showSimilarMenus,
        showStandaloneMenus,
      },
      sortingOrder: menusSortingOrder,
      sortingCriterion: menusSortingCriterion,
    })
  }, [
    refetchMenus,
    menuScrapingCluster,
    menusPage,
    menuId,
    menuFormats,
    menuStatuses,
    scrapingStatuses,
    menuNotScrapeableReasons,
    menusSortingOrder,
    menusSortingCriterion,
    scrapingClusterRoles,
    showSimilarMenus,
    showStandaloneMenus,
  ])

  const handleRegenerateComplexMenus = () => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'You are going to regenerate complex menus for the cluster.',
      onOk() {
        regenerateComplexMenusForScrapingCluster({
          variables: {
            id: menuScrapingCluster.id,
          },
        })
      },
    })
  }

  const handleTableChange = useCallback(
    ({ current: page }, filters, { order, field }) => {
      if (reasonNotSelectedError) {
        return
      }

      const sortingOrder = getTableSortingOrder(order)
      const sortingCriterion = getSortingCriterion(field, order)

      const shouldResetPage =
        menusSortingOrder !== sortingOrder ||
        menusSortingCriterion !== sortingCriterion

      if (shouldResetPage) {
        setMenusPage(DEFAULT_PAGE)
      }
      setMenusSortingOrder(sortingOrder)
      setMenusSortingCriterion(sortingCriterion)

      refetchMenus({
        id: menuScrapingCluster.id,
        filters: {
          menuId,
          menuFormats,
          menuStatuses,
          scrapingStatuses,
          scrapingClusterRoles,
          menuNotScrapeableReasons,
          showSimilarMenus,
          showStandaloneMenus,
        },
        page: shouldResetPage ? DEFAULT_PAGE : page,
        sortingOrder,
        sortingCriterion,
      })
    },
    [
      reasonNotSelectedError,
      menusSortingOrder,
      menusSortingCriterion,
      refetchMenus,
      menuScrapingCluster.id,
      menuId,
      menuFormats,
      menuStatuses,
      scrapingStatuses,
      scrapingClusterRoles,
      menuNotScrapeableReasons,
      showSimilarMenus,
      showStandaloneMenus,
    ],
  )

  const handlePageChange = useCallback(
    page => {
      if (reasonNotSelectedError) {
        Modal.error({
          title: 'Unsaved changes',
          content: 'Please resolve the error before leaving the page.',
        })
      } else {
        setMenusPage(page)
      }
    },
    [reasonNotSelectedError],
  )

  const handleRemoveMenuFromCluster = async menuId => {
    const isLeadMenu = includes(leadMenuIds, Number(menuId))

    const content = isLeadMenu
      ? 'You are going to remove this LEAD menu from the cluster. A new similar lead will be selected automatically.'
      : 'You are going to remove this menu from the cluster.'

    Modal.confirm({
      title: 'Are you sure?',
      content,
      onOk() {
        removeMenuFromMenuScrapingCluster({
          variables: {
            id: menuScrapingCluster.id,
            menuId,
          },
        })
      },
    })
  }

  const handleMarkMenuAsScrapingClusterCore = async menuId => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'You are going to mark this menu as core menu for the cluster.',
      onOk() {
        markMenuAsScrapingClusterCore({
          variables: {
            id: menuScrapingCluster.id,
            menuId,
          },
        })
      },
    })
  }

  const handleUnmarkMenuAsScrapingClusterCore = async menuId => {
    Modal.confirm({
      title: 'Are you sure?',
      content:
        'You are going to unmark this menu as core menu for the cluster.',
      onOk() {
        unmarkMenuAsScrapingClusterCore({
          variables: {
            id: menuScrapingCluster.id,
            menuId,
          },
        })
      },
    })
  }

  const [updateClusterMenu, { loading: isUpdatingMenu }] = useMutation(
    UPDATE_CLUSTER_MENU,
    {
      onCompleted: data => {
        const { id } = data.updateClusterMenu

        showSuccessNotification({
          message: `Successfully updated menu ${id}.`,
        })

        refetchMenus({
          id: menuScrapingCluster.id,
          page: menusPage,
          filters: {
            menuId,
            menuFormats,
            menuStatuses,
            scrapingStatuses,
            scrapingClusterRoles,
            menuNotScrapeableReasons,
            showSimilarMenus,
            showStandaloneMenus,
          },
          sortingOrder: menusSortingOrder,
          sortingCriterion: menusSortingCriterion,
        })
      },
      onError: ({ message }) => {
        showErrorNotification({
          message: `Failed to update menu.`,
          description: message,
        })
      },
    },
  )

  const saveMenuFormatChange = async (menuId, format) => {
    await updateClusterMenu({
      variables: {
        id: menuId,
        data: {
          format,
          notScrapeableReason: null,
        },
      },
    })
  }

  const saveNotScrapeableReasonChange = async (menuId, format, reason) => {
    await updateClusterMenu({
      variables: {
        id: menuId,
        data: {
          format: format || menusTempData[menuId]?.format,
          notScrapeableReason: reason,
        },
      },
    })
  }

  useEffect(() => {
    if (menus.length > 0) {
      forEach(menus, menu => {
        if (menu.format === HTML_NOT_SCRAPEABLE && !menusTempData[menu.id]) {
          setMenusTempData(prevState => ({
            ...prevState,
            [menu.id]: {
              ...menu,
            },
          }))
        }
      })
    }
  }, [menusTempData, menus])

  const handleMenuFormatChangeConfirmed = (value, id) => {
    const currentFormat = find(menus, { id })?.format

    if (value === currentFormat) {
      setMenusTempData(prevState => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          format: currentFormat,
        },
      }))
      setCurrentMenuId(null)
      setReasonNotSelectedError(false)
      return
    }

    setMenusTempData(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        format: value,
        notScrapeableReason:
          value !== HTML_NOT_SCRAPEABLE
            ? null
            : prevState[id]?.notScrapeableReason,
      },
    }))

    if (value !== HTML_NOT_SCRAPEABLE) {
      saveMenuFormatChange(id, value)
      setCurrentMenuId(null)
      setReasonNotSelectedError(false)
    } else {
      setCurrentMenuId(id)
      setCurrentMenuTempFormat(value)
      setReasonNotSelectedError(true)
    }
  }

  const handleMenuFormatChange = async (value, id) => {
    if (value === HTML || value === HTML_NOT_SCRAPEABLE) {
      return handleMenuFormatChangeConfirmed(value, id)
    }

    return Modal.confirm({
      title: 'Are you sure?',
      content: 'The menu will be removed from the cluster.',
      onOk() {
        handleMenuFormatChangeConfirmed(value, id)
      },
    })
  }

  const handleNotScrapeableReasonChangeConfirmed = (value, id) => {
    const format = menusTempData[id]?.format || currentMenuTempFormat

    setMenusTempData(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        notScrapeableReason: value,
      },
    }))

    saveNotScrapeableReasonChange(id, format, value)
    setCurrentMenuTempFormat(null)
    setCurrentMenuId(null)
    setReasonNotSelectedError(false)
  }

  const handleNotScrapeableReasonChange = async (value, id) => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'The menu will be removed from the cluster.',
      onOk() {
        handleNotScrapeableReasonChangeConfirmed(value, id)
      },
    })
  }

  const attachedMenusTableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '60px',
      render: id => <Link to={`/menus/${id}`}>{id}</Link>,
    },
    {
      title: 'Role',
      dataIndex: 'scrapingClusterRole',
      width: '80px',
      render: scrapingClusterRole =>
        scrapingClusterRole &&
        MENU_SCRAPING_CLUSTER_ROLES[scrapingClusterRole].name,
    },
    {
      title: 'Brand',
      dataIndex: 'brand.name',
      width: '150px',
    },
    {
      title: 'Website',
      dataIndex: 'url',
      width: '200px',
      ellipsis: true,
      render: (website, { id }) =>
        isMenuUpdateDisabled || (!isLoggedInUser && !isAdmin) ? (
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        ) : (
          <MenuUrlForm
            key={`${id}${website}`}
            id={id}
            url={website}
            disabled={reasonNotSelectedError || (!isLoggedInUser && !isAdmin)}
            onSubmit={() =>
              refetchMenus({
                id: menuScrapingCluster.id,
                page: menusPage,
                filters: {
                  menuId,
                  menuFormats,
                  menuStatuses,
                  scrapingStatuses,
                  scrapingClusterRoles,
                  menuNotScrapeableReasons,
                  showSimilarMenus,
                  showStandaloneMenus,
                },
                sortingOrder: menusSortingOrder,
                sortingCriterion: menusSortingCriterion,
              })
            }
          />
        ),
    },
    {
      title: 'Menu status',
      dataIndex: 'status',
      width: '100px',
      render: status => status && MENU_STATUSES[status].name,
    },
    {
      title: 'Scraping status',
      dataIndex: 'scrapingStatus',
      width: '100px',
      render: scrapingStatus =>
        scrapingStatus && SCRAPING_STATUSES[scrapingStatus].name,
    },
    {
      title: 'Menu format',
      dataIndex: 'format',
      width: '130px',
      render: (format, { id }) => (
        <AntSelect
          style={{ width: '100%' }}
          value={menusTempData[id]?.format || format}
          onChange={value => handleMenuFormatChange(value, id)}
          disabled={
            isMenuUpdateDisabled ||
            (!isLoggedInUser && !isAdmin) ||
            (currentMenuId && currentMenuId !== id)
          }
        >
          {menuFormatOptions}
        </AntSelect>
      ),
    },
    {
      title: 'Not scrapeable reason',
      dataIndex: 'notScrapeableReason',
      width: '150px',
      render: (notScrapeableReason, { id, format }) =>
        (menusTempData[id]?.format || format) === HTML_NOT_SCRAPEABLE ? (
          <Form.Item
            validateStatus={
              currentMenuId === id && reasonNotSelectedError ? 'error' : ''
            }
            help={
              currentMenuId === id && reasonNotSelectedError
                ? 'Please select a reason'
                : ''
            }
          >
            <AntSelect
              style={{
                width: '100%',
              }}
              value={
                menusTempData[id]?.notScrapeableReason || notScrapeableReason
              }
              onChange={value => handleNotScrapeableReasonChange(value, id)}
              disabled={
                isMenuUpdateDisabled ||
                (!isLoggedInUser && !isAdmin) ||
                (currentMenuId && currentMenuId !== id)
              }
            >
              {notScrapableReasonOptions}
            </AntSelect>
          </Form.Item>
        ) : null,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      width: '300px',
      ellipsis: true,
      render: (notes, { id }) => (
        <MenuNotesForm
          key={`${id}${notes}`}
          id={id}
          notes={notes}
          disabled={
            isMenuNotesDisabled ||
            reasonNotSelectedError ||
            (!isLoggedInUser && !isAdmin)
          }
          onSubmit={() =>
            refetchMenus({
              id: menuScrapingCluster.id,
              page: menusPage,
              filters: {
                menuId,
                menuFormats,
                menuStatuses,
                scrapingStatuses,
                scrapingClusterRoles,
                menuNotScrapeableReasons,
                showSimilarMenus,
                showStandaloneMenus,
              },
              sortingOrder: menusSortingOrder,
              sortingCriterion: menusSortingCriterion,
            })
          }
        />
      ),
    },
    {
      title: 'Attached date',
      width: '150px',
      dataIndex: 'attachedToScrapingClusterDate',
      sorter: !reasonNotSelectedError,
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Scraped date',
      width: '150px',
      dataIndex: 'scrapedDate',
      sorter: !reasonNotSelectedError,
      render: date => <LondonDateTime date={date} />,
    },
    {
      title: 'Scraping availability',
      dataIndex: 'menuScrapingAvailability',
      width: '150px',
      render: menuScrapingAvailability =>
        menuScrapingAvailability && (
          <PopoverReactJson source={menuScrapingAvailability} />
        ),
    },
    ...(isUpdateMenuScrapingClusterHidden
      ? []
      : [
          {
            title: 'Scraping cluster',
            dataIndex: 'menuScrapingClusterId',
            width: '150px',
            render: (menuScrapingClusterId, { id }) => (
              <UpdateMenuScrapingClusterForm
                menuId={id}
                allowClear={false}
                menuScrapingClusterId={menuScrapingClusterId}
                disabled={!isLoggedInUser && !isAdmin}
                onSubmit={refetchMenus}
              />
            ),
          },
        ]),
    ...(isRemoveButtonHidden && isUpdateRoleButtonHidden
      ? []
      : [
          {
            title: 'Actions',
            width: '225px',
            // eslint-disable-next-line react/prop-types
            render: ({ id, scrapingClusterRole }) => (
              <>
                {!isRemoveButtonHidden && (
                  <Button
                    type="primary"
                    onClick={() => handleRemoveMenuFromCluster(id)}
                    disabled={
                      reasonNotSelectedError || (!isLoggedInUser && !isAdmin)
                    }
                    margin="no small no no"
                  >
                    Remove
                  </Button>
                )}
                {!isUpdateRoleButtonHidden && (
                  <Button
                    type="primary"
                    onClick={() =>
                      scrapingClusterRole === CORE
                        ? handleUnmarkMenuAsScrapingClusterCore(id)
                        : handleMarkMenuAsScrapingClusterCore(id)
                    }
                    margin="no small no no"
                    disabled={
                      reasonNotSelectedError ||
                      includes([LEAD, COMPLEX], scrapingClusterRole) ||
                      (!isLoggedInUser && !isAdmin)
                    }
                  >
                    {scrapingClusterRole === CORE
                      ? 'Unmark as core'
                      : 'Mark as core'}
                  </Button>
                )}
              </>
            ),
          },
        ]),
  ]

  return (
    <>
      <Row>
        <Typography.Title level={3}>{`Menus (${total})`}</Typography.Title>
        <Col span={3}>
          <Panel margin="no small small no">
            <MenuIdInput disabled={reasonNotSelectedError} />
          </Panel>
        </Col>
        <Col span={4}>
          <Panel margin="no small small no">
            <MenuScrapingClusterRolesFilter
              mode="multiple"
              disabled={reasonNotSelectedError}
            />
          </Panel>
        </Col>
        <Col span={5}>
          <Panel margin="no small small no">
            <MenuFormatFilter
              mode="multiple"
              width="100%"
              disabled={reasonNotSelectedError}
              excludedFormats={[PDF]}
            />
          </Panel>
        </Col>
        <Col span={5}>
          <Panel margin="no small small no">
            <MenuStatusFilter
              mode="multiple"
              disabled={reasonNotSelectedError}
            />
          </Panel>
        </Col>
        <Col span={5}>
          <Panel margin="no small small no">
            <ScrapingStatusFilter
              mode="multiple"
              disabled={reasonNotSelectedError}
            />
          </Panel>
        </Col>

        {!isUpdateRoleButtonHidden && (
          <Col span={2}>
            <Button
              disabled={reasonNotSelectedError || (!isLoggedInUser && !isAdmin)}
              onClick={handleRegenerateComplexMenus}
              style={{ marginTop: '25px' }}
            >
              Regenerate complex menus
            </Button>
          </Col>
        )}
        <Col span={6}>
          <Panel margin="no small small no">
            <MenuNotScrapeableReasonsFilter
              mode="multiple"
              disabled={reasonNotSelectedError}
            />
          </Panel>
        </Col>
        <Col span={3}>
          <Panel margin="no small small no">
            <MenuShowSimilarFilter disabled={!includes(leadMenuIds, menuId)} />
          </Panel>
        </Col>
        <Col span={3}>
          <Panel margin="no small small no">
            <MenuShowStandaloneFilter disabled={!!menuId} />
          </Panel>
        </Col>
      </Row>

      <Row>
        <Table
          rowKey="id"
          loading={isLoadingMenus || isUpdatingMenu}
          columns={attachedMenusTableColumns}
          dataSource={menus}
          scroll={{ x: 2200 }}
          pagination={{
            current: menusPage,
            pageSize: 10,
            total,
            onChange: handlePageChange,
          }}
          onChange={handleTableChange}
        />
      </Row>
    </>
  )
}

MenuScrapingClusterMenusTable.propTypes = {
  menuScrapingCluster: PropTypes.object.isRequired,
  onRemoveMenuFromMenuScrapingCluster: PropTypes.func,
  isUpdateMenuScrapingClusterHidden: PropTypes.bool,
  isUpdateRoleButtonHidden: PropTypes.bool,
  isRemoveButtonHidden: PropTypes.bool,
  isMenuUpdateDisabled: PropTypes.bool,
  isMenuNotesDisabled: PropTypes.bool,
}

MenuScrapingClusterMenusTable.defaultProps = {
  onRemoveMenuFromMenuScrapingCluster: () => {},
  isUpdateMenuScrapingClusterHidden: false,
  isUpdateRoleButtonHidden: false,
  isRemoveButtonHidden: false,
  isMenuUpdateDisabled: false,
  isMenuNotesDisabled: true,
}

export default MenuScrapingClusterMenusTable
